'use client';

import { Button } from '@nextui-org/button';
import { motion, useAnimation } from 'framer-motion';
import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';

const CircleSet = ({ scale = 1, delay = 0, rotation = 0 }) => {
  const colors = ['#EC5EA4', '#EC415F', '#EEB14F', '#5BC4AE', '#C090BF'];
  const angles = useMemo(() => {
    return colors.map((_, i) => ((i * 72 + rotation) * Math.PI) / 180);
  }, [rotation]);

  return colors.map((color, i) => (
    <motion.div
      key={`${color}-${scale}-${i}`}
      className="absolute rounded-full"
      style={{
        width: `${20 * scale}%`,
        height: `${20 * scale}%`,
        top: `calc(50% - ${(20 * scale) / 2}% + ${Math.sin(angles[i]) * 60 * scale}%)`,
        left: `calc(50% - ${(20 * scale) / 2}% + ${Math.cos(angles[i]) * 60 * scale}%)`,
        backgroundColor: color,
      }}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ delay: delay + i * 0.1, duration: 0.5 }}
    />
  ));
};

export const ScrollIcon = () => {
  const controls = useAnimation();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // 初期アニメーションを開始
    controls.start({ opacity: 1, y: 0 });

    const handleScroll = () => {
      if (window.scrollY > 800) {
        setIsVisible(false);
        controls.start({ opacity: 0, y: 20 });
      } else {
        setIsVisible(true);
        controls.start({ opacity: 1, y: 0 });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [controls]);

  if (!isVisible) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={controls}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        animate={{ y: [0, 10, 0] }}
        transition={{ repeat: Infinity, duration: 1.5, ease: 'easeInOut' }}
      >
        <i className="ri-arrow-down-line text-2xl" />
      </motion.div>
    </motion.div>
  );
};

export const TopSection = () => {
  return (
    <div className="flex flex-col relative overflow-hidden pt-[100px] md:pt-[200px] h-[calc(100vh-100px)]">
      <div className="flex flex-col md:flex-row justify-center gap-12 lg:gap-24 w-full px-4">
        <motion.div
          className="flex flex-col gap-5"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="flex flex-col gap-2">
            <motion.h1
              className="text-3xl sm:text-4xl lg:text-5xl font-bold tracking-wide"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2, duration: 0.6 }}
            >
              未だ見ぬ
            </motion.h1>
            <motion.h1
              className="text-3xl sm:text-4xl lg:text-5xl font-bold tracking-wide"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4, duration: 0.6 }}
            >
              未来を手繰り寄せる
            </motion.h1>
          </div>
          <motion.p
            className="text-base sm:text-lg tracking-widest"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.6 }}
          >
            実行力とチャレンジ精神で、期待感のある社会を
          </motion.p>
          <motion.div
            className="flex justify-start"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8, duration: 0.6 }}
          >
            <Link href="/axis">
              <Button
                color="primary"
                variant="bordered"
                size="lg"
                endContent={<i className="ri-arrow-right-line" />}
                className="text-base sm:text-lg"
                style={{
                  border: '1px solid #000',
                }}
              >
                私たちの軸
              </Button>
            </Link>
          </motion.div>
        </motion.div>
        <motion.div
          className="w-4/5 sm:w-80 aspect-square relative"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          <CircleSet scale={1} delay={1} rotation={0} />
          <CircleSet scale={0.67} delay={1.5} rotation={45} />
          <CircleSet scale={0.33} delay={2} rotation={90} />
        </motion.div>
      </div>
      <div className="w-full flex flex-col items-center justify-center absolute bottom-20">
        <ScrollIcon />
      </div>
    </div>
  );
};
