import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/codebuild/output/src3422570225/src/company/node_modules/@nextui-org/button/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/codebuild/output/src3422570225/src/company/node_modules/@nextui-org/image/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/codebuild/output/src3422570225/src/company/node_modules/@nextui-org/link/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3422570225/src/company/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3422570225/src/company/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["TopSection"] */ "/codebuild/output/src3422570225/src/company/src/app/(service)/_components/TopSection.tsx");
